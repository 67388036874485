import { ReactNode } from 'react';

import cx from 'classnames';

import { translateSentence } from '@cloud-wave/neon-common-lib/app';

import Text from 'lib/common/components/Text';

import usePageProps from '../../../hooks/usePageProps';

import styles from './styles/controlled-container.module.scss';

const PageContentHeader = () => {
  const { title, subtitle } = usePageProps();

  const hasTitle = Boolean(title);
  const hasSubtitle = Boolean(subtitle);

  const titleComponent =
    hasTitle || hasSubtitle ? (
      <div className={styles['page-title']}>
        {hasTitle && <div className={styles['component-title']}>{translateSentence(title)}</div>}
        {hasSubtitle && <Text type={'label'}>{translateSentence(subtitle)}</Text>}
      </div>
    ) : null;

  return (
    <div className={cx(styles['panel-container__header'])} data-testid="page-title">
      <div>{titleComponent}</div>
      <div className={'panel-container__header__suffix'}></div>
    </div>
  );
};

export const ControlledPageContainer = ({ children }: { children: ReactNode }) => {
  return (
    <div className={cx(styles['controlled-container'])}>
      <PageContentHeader />
      <div className={cx(styles['controlled-container__content'])}>{children}</div>
    </div>
  );
};
